export default {
  brand: 'Brand',
  default: 'Default',
  defaultDestructive: 'Default Destructive',
  destructive: 'Destructive',
  glass: 'Glass',
  large: 'Large',
  medium: 'Medium (default)',
  neutral: 'Neutral',
  outline: 'Outline',
  outlineDestructive: 'Outline Destructive',
  primary: 'Primary',
  round: 'Round (Default)',
  roundedSquare: 'Rounded Square',
  secondary: 'Secondary',
  small: 'Small',
  square: 'Square',
  xLarge: 'X-Large',
  xSmall: 'X-Small',
};
