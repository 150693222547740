export default {
  backTitle: 'Home',
  collapse: 'Collapse',
  icons: {
    back: 'keyboard_arrow_left',
    games: 'games',
    help: 'help',
    settings: 'settings_applications',
    user: 'person',
  },
  logoTitle: 'Featherweight Design',
  optionLabels: {
    games: 'Games',
    help: 'Help',
    settings: 'Settings',
    user: 'User',
  },
  optionTitles: {
    settings: 'Settings',
  },
  paths: {
    allGames: '/games/all',
    contact: '/help/contact',
    feedback: '/help/feedback',
    home: '/',
    logout: '/logout',
    notifications: '/user/notifications',
    myInfo: '/user/my-info',
    puzzle: '/games/puzzle',
    racing: '/games/racing',
    rpg: '/games/rpg',
    settings: '/settings',
  },
  subOptionLabels: {
    allGames: 'All Games',
    contact: 'Contact Us',
    feedback: 'Feedback',
    logout: 'Logout',
    notifications: 'Notifications',
    myInfo: 'My Info',
    puzzle: 'Puzzle',
    racing: 'Racing',
    rpg: 'RPG',
  },
};
