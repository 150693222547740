export default {
  bestDogs: 'Best Dogs',
  bestIceCream: 'Best Ice Cream',
  cat: 'Cat',
  chocolate: 'Chocolate',
  cookieDough: 'Cookie Dough',
  cthulhu: 'Cthulhu',
  disabled: 'Disabled',
  disabledChecked: 'Disabled Checked',
  disabledLabel: 'Disabled Checkboxes',
  dog: 'Dog',
  errorMessage: 'Please pick a favorite pet',
  goldFish: 'Gold Fish',
  guineaPig: 'Guinea Pig',
  hedgehog: 'Hedgehog',
  juniper: 'Juniper',
  luna: 'Luna',
  ocelot: 'Ocelot',
  other: 'Other',
  pooch: 'Pooch',
  saltedOreo: 'Salted Oreo',
  torin: 'Torin',
  vanilla: 'Vanilla',
};
